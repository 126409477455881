import { action } from "typesafe-actions";
import { IEnvConfig } from "./envConfig.types";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";

export const FETCH_ENV_CONFIG_LIST_PROGRESS = "FETCH_ENV_CONFIG_LIST_PROGRESS";
export const FETCH_ENV_CONFIG_LIST_SUCCESS = "FETCH_ENV_CONFIG_LIST_SUCCESS";
export const FETCH_ENV_CONFIG_LIST_FAILED = "FETCH_ENV_CONFIG_LIST_FAILED";

export const fetchEnvConfigListProgress = () =>
  action(FETCH_ENV_CONFIG_LIST_PROGRESS);
export const fetchEnvConfigListSuccess = (
  data: IEnvConfig[],
  totalRecords: number
) => action(FETCH_ENV_CONFIG_LIST_SUCCESS, { data, totalRecords });
export const fetchCSIOListFailed = () => action(FETCH_ENV_CONFIG_LIST_FAILED);

export const fetchEnvConfigAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEnvConfigListProgress());

      let finalUrl = `/general/get-environment-configuration?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchValue.length > 0) {
        finalUrl = `/general/get-environment-configuration?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }

      const res = await api.get(finalUrl);
      const data: IEnvConfig[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchEnvConfigListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCSIOListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const upsertEnvConfig =
  (
    data: IEnvConfig,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/general/upsert-environment-configuration", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Env configuration saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_ENV_CONFIG_STATE = "CLEAR_ENV_CONFIG_STATE";

export const clearEnvConfigState = () => action(CLEAR_ENV_CONFIG_STATE);