import { action } from "typesafe-actions";

import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { IHabitationalRiskLocation } from "./habitationalRiskLocation.types";

export const FETCH_HABITATIONAL_RISK_LOCATION_LIST_PROGRESS =
  "FETCH_HABITATIONAL_RISK_LOCATION_LIST_PROGRESS";
export const FETCH_HABITATIONAL_RISK_LOCATION_LIST_SUCCESS =
  "FETCH_HABITATIONAL_RISK_LOCATION_LIST_SUCCESS";
export const FETCH_HABITATIONAL_RISK_LOCATION_LIST_FAILED =
  "FETCH_HABITATIONAL_RISK_LOCATION_LIST_FAILED";

export const fetchHabitationalRiskListProgress = () =>
  action(FETCH_HABITATIONAL_RISK_LOCATION_LIST_PROGRESS);
export const fetchHabitationalRiskListSuccess = (
  data: IHabitationalRiskLocation[]
) => action(FETCH_HABITATIONAL_RISK_LOCATION_LIST_SUCCESS, { data });
export const fetchHabitationalRiskListFailed = () =>
  action(FETCH_HABITATIONAL_RISK_LOCATION_LIST_FAILED);

export const fetchHabitationalRiskListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalRiskListProgress());

      let finalUrl = `/policy/get-habitational-risk-location?customer_policy_id=${customerPolicyId}`;

      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IHabitationalRiskLocation[] = res.data.data;
      dispatch(fetchHabitationalRiskListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalRiskListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalRiskLocationAsync =
  (
    riskLocation: IHabitationalRiskLocation,
    customerPolicyId: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/create-habitational-risk-location`, {
        ...riskLocation,
        customer_policy_id: customerPolicyId,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Risk location saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_HABITATIONAL_RISK_LOCATION_PROGRESS =
  "FETCH_HABITATIONAL_RISK_LOCATION_PROGRESS";
export const FETCH_HABITATIONAL_RISK_LOCATION_SUCCESS =
  "FETCH_HABITATIONAL_RISK_LOCATION_SUCCESS";
export const FETCH_HABITATIONAL_RISK_LOCATION_FAILED =
  "FETCH_HABITATIONAL_RISK_LOCATION_FAILED";

export const fetchHabitationalRiskProgress = () =>
  action(FETCH_HABITATIONAL_RISK_LOCATION_PROGRESS);
export const fetchHabitationalRiskSuccess = (data: IHabitationalRiskLocation) =>
  action(FETCH_HABITATIONAL_RISK_LOCATION_SUCCESS, { data });
export const fetchHabitationalRiskFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_RISK_LOCATION_FAILED, { errorMessage });

export const fetchHabitationalRiskAsync =
  (
    customerPolicyId: number,
    riskLocationNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalRiskProgress());
      const res = await api.get(
        `/policy/get-habitational-risk-location?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${riskLocationNumber}`
      );
      const data: IHabitationalRiskLocation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalRiskSuccess({ ...data[0] }));
      } else {
        dispatch(
          fetchHabitationalRiskFailed(
            "Oops! We couldn't find any records associated with your risk location number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalRiskFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_HABITATIONAL_RISK_LOCATION_STATE =
  "CLEAR_HABITATIONAL_RISK_LOCATION_STATE";
export const CLEAR_HABITATIONAL_RISK_LOCATION =
  "CLEAR_HABITATIONAL_RISK_LOCATION";

export const clearHabitationalRiskLocationState = () =>
  action(CLEAR_HABITATIONAL_RISK_LOCATION_STATE);

export const clearHabitationalRiskLocation = () =>
  action(CLEAR_HABITATIONAL_RISK_LOCATION);
