import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { AddOutlined, AppRegistration } from "@mui/icons-material";
import { ContextMenu } from "../../../../../../components/ContextMenu/ContextMenu";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper, useRecordCounts } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import {
  clearHabitationalRiskLocationState,
  fetchHabitationalRiskListAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocationActions";
import { IHabitationalRiskLocation } from "../../../../../../redux/habitationalPolicy/habitationalRiskLocation/habitationalRiskLocation.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";
import { clearRecordCounts, fetchRecordCountsAsync } from "../../../../../../redux/common/commonActions";
import { createTabsWithRecordcounts } from "../../../../../../helpers";

export const HabitationalRiskLocationList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.riskLocation
  );
  const dispatch = useDispatchWrapper();

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/habitational-policy/${customerId}/risk-location/${customerPolicyId}/application`
    );
  };

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(
        fetchHabitationalRiskListAsync(customerPolicyId, tabs.selectedTab)
      );
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearHabitationalRiskLocationState());
    };
  }, [customerPolicyId]);

  const riskLocationTable: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        headingAlign: "center",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalRiskLocation) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/risk-location/${customerPolicyId}/application/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "incremental_number",
        headerName: "Loc #",
        fieldName: "incremental_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalRiskLocation) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/risk-location/${customerPolicyId}/application/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "create_ts",
        headerName: "Created Date",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 20,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
    ],
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
    rows: data,
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();
  
    React.useEffect(() => {
        const tabsData = createTabsWithRecordcounts(
          [
            {
              label: "Active",
              value: "ACTIVE",
              variant: "success",
            },
            {
              label: "InActive",
              value: "INACTIVE",
              variant: "error",
            },
          ],
          recordsCounts
        );
    
        setTableTabs(tabsData);
      }, [recordsCounts]);
    
      React.useEffect(() => {
        dispatch(
          fetchRecordCountsAsync(
            "latest_policy_habitational_claims",
            "status",
            dateState.dates.fromDate,
            dateState.dates.toDate
          )
        );
        return () => {
          dispatch(clearRecordCounts());
        };
      }, [dateState.dates.fromDate, dateState.dates.toDate]);

  return (
    <PageContainer title="View RiskLocation" description="this is innerpage">
      <PageTitleBar
        heading="RiskLocation"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...riskLocationTable} />
    </PageContainer>
  );
};
