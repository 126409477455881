import { Avatar, Button, MenuItem, Stack } from "@mui/material";
import React from "react";
import { useDispatchWrapper, useRecordCounts } from "../../hooks";

import { AddOutlined } from "@mui/icons-material";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import { CustomSelect } from "../../components/formsComponents";
import { LoadState, MODULE_IDS } from "../../constants/enums";
import { PageContainer } from "../../components/container/PageContainer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import {
  clearCSIOState,
  fetchCSIOListAsync,
} from "../../redux/csio/csioActions";
import { CustomLink } from "../../components/CustomLink/CustomLink";
import { ICSIORaw } from "../../redux/csio/csio.types";
import { CSIOMapRightPanel } from "./CSIOMapRightPanel";
import { createTabsWithRecordcounts } from "../../helpers";
import { PageTitleBar } from "../../components/PageTitleBar/PageTitleBar";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../redux/common/commonActions";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { InitializeCSIOMapping } from "./InitializeCSIOMapping/InitializeCSIOMapping";
import { CSIOSendReceive } from "./CSIOSendReceiveDialog";
import { useTableV2State } from "../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../components/TableV2/DataTableV2";

export const CSIOLIst: React.FC = () => {
  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.csio
  );
  const dispatch = useDispatchWrapper();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "allTimes" });

  const [openCSIO, setOpenCSIO] = React.useState<ICSIORaw | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openMap, setOpenMap] = React.useState<boolean>(false);

  const [messageType, setMessageType] = React.useState<string | "-1">("-1");
  const navigate = useNavigate();

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    let mapvalue;
    if (tabs.selectedTab === "-1") {
      mapvalue = "-1";
    } else if (tabs.selectedTab === "Matched") {
      mapvalue = "1";
    } else {
      mapvalue = "0";
    }
    dispatch(
      fetchCSIOListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        dateState.dates,
        searchState,
        mapvalue,
        messageType
      )
    );
  };
  React.useEffect(() => {
    fetchList();
  }, [
    dateState.dates,
    tablePagination,
    searchState,
    tabs.selectedTab,
    messageType,
  ]);

  const csioTableProps: IDataTableV2Props = {
    isPagination: true,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [
          {
            key: "cl",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={messageType}
                onChange={(e) => setMessageType(e.target.value as string)}
              >
                <MenuItem value={"-1"}>{"All Message Types"}</MenuItem>;
                <MenuItem value="EDI">EDI</MenuItem>
                <MenuItem value="XML">XML</MenuItem>
                <MenuItem value="eDOC">eDOC</MenuItem>
              </CustomSelect>
            ),
          },
        ],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    totalRecords: totalRecords,
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(`/manage-csio-tab/${row.message_guid}`);
              }}
            />
          );
        },
      },
      {
        key: "csio",
        headerName: "",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        enableSorting: false,

        onRowCellRender: (values, row) => {
          return (
            <Button variant="text" onClick={() => setOpenCSIO(row)}>
              View XML & Map
            </Button>
          );
        },
      },
      {
        key: "edocs",
        headerName: "",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        enableSorting: false,

        onRowCellRender: (values, row) => {
          return (
            <CustomLink
              to={`/csio-edocs-list/manage-csio-edoc/${row.message_guid}`}
              label={"View Edocs"}
            />
          );
        },
      },
      {
        key: "MessageGUID",
        headerName: "Message ID",
        fieldName: "message_guid",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/manage-csio-tab/" + row.message_guid}
              label={row.message_guid}
            />
          );
        },
      },
      {
        key: "MessageType",
        headerName: "Message Type",
        fieldName: "message_type",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },
      {
        key: "is_map",
        headerName: "Map",
        fieldName: "is_map",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
        onRowCellRender: (value, row) => {
          return row.is_map === 1 ? (
            <CheckCircleRoundedIcon fontSize="medium" color="success" />
          ) : (
            <CancelIcon fontSize="medium" color="error" />
          );
        },
      },
      {
        key: "customer_policy_id",
        headerName: "Policy Id",
        fieldName: "customer_policy_id",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },
      {
        key: "policy_status",
        headerName: "Policy Status",
        fieldName: "policy_status",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },
      {
        key: "FromEmailAddress",
        headerName: "From Email Address",
        fieldName: "from_email_address",
        enableSorting: false,
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 25,
      },
      {
        key: "DateTime",
        headerName: "Date and Time",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME,
        // exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "CreatedDate",
        headerName: "Created Date",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 20,
        enableSorting: false,
      },
    ],
    rows: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
  };

  const handleFetch = () => {
    setOpenMap((prev) => !prev);
  };

  const handleMapping = () => {
    setOpen((prev) => !prev);
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();
  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_csio_retrieve_messages",
        "is_map",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Matched", value: "Matched", variant: "success" },
        { label: "Unmatched", value: "Unmatched", variant: "error" },
      ],
      recordsCounts
        .filter((x) => x.name !== null)
        .map((x) => ({
          name: x.name.toString() === "0" ? "Unmatched" : "Matched",
          cnt: x.cnt,
        }))
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCSIOState());
    };
  }, []);

  return (
    <>
      <PageContainer title="View Csio" description="this is innerpage">
        <PageTitleBar
          heading="CSIO"
          rightHeading={
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="contained"
                startIcon={<AddOutlined />}
                onClick={handleFetch}
              >
                Send/Receive
              </Button>
              <Button
                variant="contained"
                startIcon={<AddOutlined />}
                onClick={handleMapping}
              >
                Initialize Mapping
              </Button>
            </Stack>
          }
        />
        <DataTableV2 {...csioTableProps} />
      </PageContainer>
      {openCSIO && (
        <CSIOMapRightPanel
          open={true}
          messageGUID={openCSIO.message_guid}
          onClose={() => setOpenCSIO(null)}
        />
      )}
      {open && (
        <InitializeCSIOMapping open={open} onClose={() => setOpen(false)} />
      )}
      {openMap && (
        <CSIOSendReceive open={openMap} onClose={() => setOpenMap(false)} />
      )}
    </>
  );
};
