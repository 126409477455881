import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper, useRecordCounts } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import {
  clearHabitationalIntrestedState,
  fetchHabitationalIntrestedListAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalInterestedParty/habitationalInterestedActions";
import { IHabitationalInterested } from "../../../../../../redux/habitationalPolicy/habitationalInterestedParty/habitationalInterested.types";
import { DataTableV2RowRenderType, IDataTableV2Props } from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import { createTabsWithRecordcounts } from "../../../../../../helpers";
import { clearRecordCounts, fetchRecordCountsAsync } from "../../../../../../redux/common/commonActions";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { AddOutlined } from "@mui/icons-material";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";

export const InterestedPartyList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.interested
  );
  const dispatch = useDispatchWrapper();

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/habitational-policy/${customerId}/risk-location-tab/${customerPolicyId}/interested-party`
    );
  };
  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(
        fetchHabitationalIntrestedListAsync(customerPolicyId, tabs.selectedTab)
      );
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearHabitationalIntrestedState());
    };
  }, [customerPolicyId, tabs.selectedTab]);

  const interestedPartyProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalInterested) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/risk-location-tab/${customerPolicyId}/interested-party/${row.incremental_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalInterested) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/risk-location-tab/${customerPolicyId}/interested-party/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "location_number",
        headerName: "Risk Location #",
        fieldName: "location_number",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
      },
      {
        key: "mortgage_or_loss_payee_code",
        headerName: "Interest Party Code",
        fieldName: "mortgage_or_loss_payee_code",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // exportCellWidth: 20,
      },
      {
        key: "mortgage_or_loss_payee_name",
        headerName: "Interested Party",
        fieldName: "mortgage_or_loss_payee_name",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
      },
      {
        key: "mortgage_or_loss_payee_nature_of_interest",
        headerName: "Interest",
        fieldName: "mortgage_or_loss_payee_nature_of_interest",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
      },

      {
        key: "mortgage_or_loss_payee_address",
        headerName: "Address",
        fieldName: "mortgage_or_loss_payee_address",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        onRowCellValueRender: (value, row: IHabitationalInterested) => {
          return (
            row.mortgage_or_loss_payee_address +
            ", " +
            row.mortgage_or_loss_payee_city +
            ", " +
            row.mortgage_or_loss_payee_province_or_state +
            ", " +
            row.mortgage_or_loss_payee_postal_or_zip_code
          );
        },
      },
      {
        key: "insert_ts",
        headerName: "Created Date",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 20,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalInterested) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/risk-location-tab/${customerPolicyId}/interested-party/${row.incremental_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
    rows: data,
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
     useRecordCounts();
   
     React.useEffect(() => {
         const tabsData = createTabsWithRecordcounts(
           [
             {
               label: "Active",
               value: "ACTIVE",
               variant: "success",
             },
             {
               label: "InActive",
               value: "INACTIVE",
               variant: "error",
             },
           ],
           recordsCounts
         );
     
         setTableTabs(tabsData);
       }, [recordsCounts]);
     
       React.useEffect(() => {
         dispatch(
           fetchRecordCountsAsync(
             "latest_policy_habitational_claims",
             "status",
             dateState.dates.fromDate,
             dateState.dates.toDate
           )
         );
         return () => {
           dispatch(clearRecordCounts());
         };
       }, [dateState.dates.fromDate, dateState.dates.toDate]);
 
   return (
     <>
       <PageTitleBar
         heading="Intrested Parties"
         rightHeading={
           <Button
             variant="contained"
             onClick={handleCreate}
             startIcon={<AddOutlined />}
           >
             Create
           </Button>
         }
       />
       <DataTableV2 {...interestedPartyProps} />
     </>
   );
 };
 
