/* eslint-disable react-hooks/exhaustive-deps */
import Avatar from "@mui/material/Avatar";
import React from "react";
import { StandadCard } from "../../components/Cards";
import { PageContainer } from "../../components/container/PageContainer";
import { DataTable } from "../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../components/Table/plugins";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { ViewLeadDetails } from "./components/ViewLeadDeatils/ViewLeadDetails";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../hooks";
import { CustomSelect } from "../../components/formsComponents";
import { Button, MenuItem, Typography } from "@mui/material";
import {
  capitalizeWords,
  createTabsWithRecordcounts,
  formatDateWithTime,
  renderDropdownValue,
} from "../../helpers";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import { DateFilterDropdown } from "../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { CustomLink } from "../../components/CustomLink/CustomLink";
import { MessagesDialogWrapper } from "../../components/MessagesDialogs/MessagesDialogWrapper/MessagesDialogWrapper";
import { CallDialog } from "../../components/MessagesDialogs/CallDialog/CallDialog";
import { WhatsAppDialog } from "../../components/MessagesDialogs/WhatsAppDialog/WhatsAppDialog";
import { SmsDialog } from "../../components/MessagesDialogs/SmsDialog/SmsDialog";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import { EmailComposeRightPanel } from "../../components/MessagesDialogs/EmailCompose/EmailCompose";
import {
  clearLeadsState,
  fetchLeadsListAsync,
} from "../../redux/leads/leadsActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { LoadState, MODULE_IDS, MODULE_NAMES } from "../../constants/enums";
import { ILead } from "../../redux/leads/leads.types";
import { LeadOppStatus } from "../../components/LeadOppStatus/LeadOppStatus";
import { IconicMultiColumnCard } from "../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../redux/common/commonActions";
import { LEAD_STATUS } from "../../constants/constants";
import { IPremissions } from "../../security/RolesProvider/RolesProvider";
import { RoleBasedCSVExportPlugin } from "../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RoleBasedEmailRightPanel } from "../../security/RoleBasedComponents/RoleBasedEmailRightPanel/RoleBasedEmailRightPanel";
import { RoleBasedMessages } from "../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import { AddOutlined, AppRegistration } from "@mui/icons-material";
import { TaskAssignedTo } from "../tasks/BeforeCreate/TaskAssignedTo";
import { LeadsAssignedTo } from "./components/LeadsAssignedTo/LeadsAssignedTo";
import { ITableTab } from "../../components/Table/components/TableTabs/TableTabs.types";
import { PageTitleBar } from "../../components/PageTitleBar/PageTitleBar";
import { useTableV2State } from "../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../components/TableV2/DataTableV2";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";

export const Leads: React.FC<{
  //  premissions: IPremissions
}> = (props) => {


  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.leads
  );

  const [selectedLeads, setSelectedLeads] = React.useState<{
    isAllSelected: boolean;
    leads: ILead[];
  }>({
    isAllSelected: false,
    leads: [],
  });

  const [openView, setOpenView] = React.useState<ILead | null>(null);
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [isConvertStatus, setisConvertStatus] = React.useState<
    ILead["isconvert_status"] | "-1"
  >("-1");

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    dispatch(
      fetchLeadsListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        tabs.selectedTab,
        dateState.dates,
        searchState,
        isConvertStatus
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tablePagination,
    dateState.dates,
    tabs.selectedTab,
    searchState,
    isConvertStatus,
  ]);

  const menuItems =
    selectedLeads.leads.length > 0 || selectedLeads.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const leadsTableProps: IDataTableV2Props = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading !== LoadState.Loaded,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [
          ...menuItems,
          {
            key: "isconvert_status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: {
                  md: "80px",
                  lg: "",
                  xl: "120px",
                } }}
                value={isConvertStatus}
                onChange={(e: any) =>
                  setisConvertStatus(e.target.value as "Leads")
                }
              >
                <MenuItem value="-1">Leads</MenuItem>
                <MenuItem value="OPPORTUNITY">Opportunity</MenuItem>
              </CustomSelect>
            ),
          },
          // {
          //   key: "csvExport",
          //   onRender: (columns, items) => (
          //     <RoleBasedCSVExportPlugin
          //       columns={columns}
          //       items={items}
          //       filePrefixName="Report"
          //       moduleId={MODULE_IDS.LEADS}
          //     />
          //   ),
          // },
        ],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    // initialSortConfig: {
    //   insert_ts: {
    //     direction: "desc",
    //     filedName: "insert_ts",
    //   },
    // },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row: ILead) => {
          const menuItems: any = [
            {
              icon: <AppRegistration />,
              label: "Edit Consent",
              onClick: () => {},
            },
          ];
          return (
            <StandardTableActions
              commentBoxTypeId={row.lead_id as string}
              onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate("/leads/manage/" + row.lead_id);
              }}
              more={{ menuItems: menuItems }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ILead) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "assigned_to_name",
        headerName: "Assigned To",
        fieldName: "assigned_to_name",
        width: '400px',
        mdWidth: '300px',
        lgWidth: '400px',
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return <LeadsAssignedTo lead={row} onComplete={fetchList} />;
        },
      },

      {
        key: "first_name",
        headerName: "Name",
        fieldName: "first_name",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        enableSorting: true,

        onRowCellRender: (value, row: ILead) => {
          return (
            <CustomLink
              to={"/leads/manage/" + row.lead_id}
              label={row.first_name + " " + row.last_name}
            />
          );
        },
      },
      {
        key: "business_name",
        headerName: "Business Name",
        fieldName: "business_name",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
        enableSorting: true,
      },
      {
        key: "insurance_type",
        headerName: "Quotes For",
        fieldName: "insurance_type",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
        enableSorting: true,
      },
      {
        key: "insurance_sub_type",
        headerName: "Type",
        fieldName: "insurance_sub_type",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "producer_name",
        headerName: "Producer",
        fieldName: "producer_name",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 30,
        enableSorting: true,
      },

      {
        key: "phone",
        headerName: "Phone",
        fieldName: "phone",
        // exportCellWidth: 25,
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
        onRowCellRender: (value, row: ILead) => {
          return (
            <RoleBasedMessages phone={row.phone} moduleId={MODULE_IDS.LEADS} />
          );
        },
      },
      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
        mdWidth: "200px",
        onRowCellRender: (value, row: ILead) => {
          return (
            <RoleBasedEmailRightPanel
              displayLabel={row.email}
              toEmail={row.email}
              moduleId={MODULE_IDS.LEADS}
              id={row.lead_id}
              history={{
                module_name: MODULE_NAMES.LEADS,
                module_code: row.lead_id as string
              }}
            />
          );
        },
      },

      {
        key: "insert_ts",
        headerName: "Date Created",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.DATE_TIME,
        // exportCellWidth: 25,
        // onExportRender: (values) => {
        //   return formatDateWithTime(values.value);
        // },
      },

      {
        key: "date",
        headerName: "Date to Contact",
        fieldName: "date",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 25,
        // onExportRender: (values) => {
        //   return values.value ? formatDateWithTime(values.value) : "--";
        // },
      },
    ],
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },

    rows: list,

    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: ILead[]) => {
      setSelectedLeads({ isAllSelected: isAllSelected, leads: rows });
    },
  };

  const handleCreate = () => {
    navigate("/leads/manage");
  };

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        {
          label: "New",
          value: "ACTIVE",
          variant: "warning",
        },
        {
          label: "In-Progress",
          value: "INPROGRESS",
          variant: "success",
        },
        {
          label: "In-Process",
          value: "In-Process",
          variant: "success",
        },

        {
          label: "Duplicate",
          value: "DUPLICATE",
          variant: "secondary",
        },
        { label: "Dead", value: "DEAD", variant: "error" },
        { label: "Spam", value: "SPAM", variant: "grey" },
        {
          label: "Recycle",
          value: "RECYCLE",
          variant: "primary",
        },
      ],
      recordsCounts
    );

    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_leads_without_opportunity_status",
        "status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);

  React.useEffect(() => {
    return () => {
      dispatch(clearLeadsState());
    };
  }, []);

  return (
    <PageContainer title="View Leads" description="this is innerpage">
      {/* <Breadcrumb title="View Leads" items={BCrumb} /> */}

      {/* <IconicMultiColumnCard
        loading={recordCountsLoading !== LoadState.Loaded}
        columns={recordsCounts.map((item) => {
          return {
            heading: capitalizeWords(item.name),
            value: item.cnt,
            colSize: item.name &&item.name.length > 10 ? 3 : 2,
          };
        })}
      /> */}

      <PageTitleBar
        heading="Leads"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...leadsTableProps} />
      {openView && (
        <ViewLeadDetails
          open={true}
          lead={openView}
          onClose={() => setOpenView(null)}
        />
      )}
    </PageContainer>
  );
};
