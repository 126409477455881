export enum LoadState {
  NotLoaded = 0,
  InProgress = 1,
  Loaded = 2,
  Failed = 3,
}

export enum SaveState {
  NotStarted = 0,
  Progress = 1,
  Saved = 2,
}

export enum USER_ROLES {
  ADMIN = 1,
  CSR = 2,
  PRODUCER = 3,
  MARKETER = 4,
  BRANCH_MANAGER = 5,
}

export enum TASK_SUBTYPE {
  VEHICLE_CHANGE = "Vehicle Change",
  DRIVER_CHANGE = "Driver Change",
  COVERAGE_CHANGE = "Coverage Change",
  GARAGE_PLATE_CHANGE = "Garage Plate Change",
  INTERESTED_PARTY_CHANGE = "Interested Party Change",
  LESSOR_CHANGE = "Lessor Change",
  ADDRESS_CHANGE = "Address Change",
  RISK_LOCATION_CHANGE = "Risk Location Change",
  CANCELLATION_REINSTALLMENT = "Cancellation/Reinstatement",
  BUSINESS_SUBMISSION = "Business Submission",
  GENERAL_CHANGE = "General Change",
  PAYMENT_AUTH_CHANGE = "Payment Authorization Change",
  LAO_BOR = "LOA/BOR",
  LETTER_OF_EXPERIENCE = "Letter of Experience",

  NEW_BUSINESS = "Business",
  NEW_PERSONAL_AUTO = "Personal Auto",
  NEW_HOME = "Home",
  NEW_COMMERCIAL_AUTO = "Commercial Auto",
  NEW_PERSONAL_AND_HOME = "Personal Auto & Home",
  REMARKET_COMMERCIAL_AUTO = "Commercial Auto",
  REMARKET_HOME = "Home",
  REMARKET_BUSINESS = "Business",
  REMARKET_PERSONAL_AUTO = "Personal Auto",
  REMARKET_PERSONAL_AND_HOME = "Personal Auto & Home",
  QUOTE_REQUEST = "Quote Request",
  TASK_CERTIFICATE_OF_INSURANCE = "Certificate of Insurance"
}

export enum POLICIES {
  BUSINESS_POLICY = "Business Policy",
  AUTOMOBILE_POLICY = "Automobile Policy",
  HABITATIONAL_POLICY = "Habitational Policy",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto Policy",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Financial Policy",
}

export enum POLICIES_NAMES {
  BUSINESS_POLICY = "Business",
  AUTOMOBILE_POLICY = "Automobile",
  HABITATIONAL_POLICY = "Habitational",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Financial",
}

export enum ERROR_MESSAGES {
  "NO_RECORD_FOUND"= "Unfortunately, there are no records available at the moment.",
  "SERVER_ERROR" = "Someting went to be wrong!",
  "POLICY_NOT_FOUND" = "Oops! We couldn't find any records at the moment. Please ensure that the provided information is accurate and try again.",
}

export enum MODULE_IDS {
  NONE = -1,
  LEADS = 1,
  OPPORTUNITY = 2,
  ALL_POLICY = 46,
  BUSINESS_POLICY = 3,
  COMMERCIAL_AUTO = 4,
  AUTOMOBILE = 5,
  HABITATIONAL = 6,
  LIFE_AND_FINCANCIAL = 8,
  CUSTOMER = 10,
  LEGAL = 13,
  HR_APPLICATION = 14,
  HR_ONBOARDING = 15,
  HR_OFFBOARDING = 16,
  HR_REQUEST_FORMS = 50,
  INSURER = 17,
  UNDERWRITERS = 18,
  CSIO_COMPANY = 19,
  POLICY_COMISSION = 20,
  INSURER_CONTACTS = 21,
  BRANCH_OFFICES = 23,
  BROKER_CODES = 24,
  INSURER_SUB = 25,
  ADDITIONAL_INTERESTS = 26,
  INTERESTED_PARTIES = 27,
  ROLE = 28,
  ROLE_MODULE = 29,
  ALL_TASK = 35,
  TASK_CALENDER = 36,
  TASK_TASKBOARD = 37,
  TASK_RENEWALS = 38,
  TASK_BINDERS = 39,
  TASK_MARKETING = 40,
  TASK_NOTICES = 33,
  NOTES = 12,
  INSURANCE_REPORTS = 47,
  TEMPLATES = 31,
  USERS = 32,
  STAFF_USER = 49,
  BUSINESS_POLICY_ADMIN_MANAGER = 42,
  COMM_AUTO_POLICY_ADMIN_MANAGER = 43,
  AUTO_POLICY_ADMIN_MANAGER = 41,
  HABITATIONAL_POLICY_ADMIN_MANAGER = 44,
  LIFE_AND_FINCANCIAL_POLICY_ADMIN_MANAGER = 45,
  ALL_BINDERS = 53,
  G4_SIGN=52,
  BROKER_CONTACTS =55,
  EQUIPMENT=63,
  COMPANY_EQUIPMENT=67,
  COMMUNICATION=65,
  OLD_BUSINESS_TRACKER=68,
  OLD_INSURANCE_REPORTS=69,
  CSIO=59,
  CSIO_EDOCS=64,
  CSIO_MAPPING=73,
  CSIO_CRON=74,
}



export enum TRANSACTION_TYPE_ENUM {
  AuditPlus = "+ Audit",
  AuditMinus = "- Audit",
  Tax = "Tax",
  Reinstate = "Reinstate",
  Reissue = "Reissue",
  Rewrite = "Re-write",
  Correction = "Correction",
  Cancellation = "Cancellation",
  EndorsementPlus = "+ Endorsement",
  EndorsementMinus = "- Endorsement",
  Renewal = "Renewal",
  New = "New",
  FeeNSFFee = "Fee - NSF Fee",
  FeeServiceFee = "Fee - Service Fee",
  FeeInsuranceReports = "Fee - Insurance Reports",
  FeeFacilityFee = "Fee - Facility Fee",
  FeeMGAInsurerFee = "Fee - MGA/Insurer Fee",
  Payment = "Payment"
}

export enum MODULE_NAMES  {
   LEADS = "Leads",
   OPPORTUNITY = "Opportunity",
   CUSTOMER = "Customers",
   POLICIES = "Policies",
   LEGALS = "Legals"

}

export enum ROLES  {
  ADMIN= "ADMIN",
  CSR="CSR",
  PRODUCER_CL = "Producer-CL",
  PRODUCER = "Producer",
  HR = "HR",
  MANAGEMENT = "Management",
  CSR_HEADOFFICE= "CSR - Headoffice",
  BRANCH_MANAGER = "Branch Manager",
  ACCOUNTANT = "Accountant",
}